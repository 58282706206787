<template>
  <div class="space_">
    <ConfirmProfile />
  </div>
</template>

<script>
import ConfirmProfile from '@/components/jobSeeker/profile/ConfirmProfile.vue'

export default {
  components: {
    ConfirmProfile,
  },
}
</script>

<style scoped>
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .space_ {
    width: 50%;
    margin: 0 auto;
  }
}
</style>
