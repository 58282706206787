<template>
  <div class="">
    <h2>プロフィール</h2>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1">
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar
              size="35"
              src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-green.png?alt=media&token=decd9d77-1d03-4797-b794-a03bf691d4e5"
              variant="light-primary"
            />
          </div>
          <div class="">
            事前にプロフィールをご入力頂くと、より早くお仕事をご紹介できます！
          </div>
        </div>
      </div>
    </b-alert>
    <b-card
      v-if="inputForm"
      title=""
    >
      <b-row>
        <b-col
          cols="4"
          class="text-right"
        >
          氏名
        </b-col>
        <b-col>
          <div>
            {{ inputForm.lastName }} {{ inputForm.firstName }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="4"
          class="text-right"
        >
          ふりがな
        </b-col>
        <b-col>
          <div>
            {{ inputForm.lastName_kana }} {{ inputForm.firstName_kana }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="4"
          class="text-right"
        >
          性別
        </b-col>
        <b-col>
          <div v-if="inputForm.gender">
            {{ inputForm.gender.labelName || '' }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="4"
          class="text-right"
        >
          生年月日
        </b-col>
        <b-col>
          <div class="">
            {{ inputForm.birthday }}生 ({{ showAge(inputForm.birthday) }}歳)
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="4"
          class="text-right"
        >
          住所
        </b-col>
        <b-col>
          <div v-if="inputForm.address">
            <div
              class=""
            >
              {{ showZip(inputForm.address.zipcode) || '' }}
            </div>
            <div class="mt-1">
              {{ inputForm.address.prefecture || '' }}{{ inputForm.address.address1 || '' }}
            </div>
            <div class="mt-1">
              {{ inputForm.address.address2 || '' }}
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="4"
          class="text-right"
        >
          メールアドレス
        </b-col>
        <b-col>
          <div class="">
            {{ inputForm.mail || '' }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="4"
          class="text-right"
        >
          電話番号
        </b-col>
        <b-col>
          <div class="">
            {{ inputForm.phone || '' }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <!-- submit and reset -->
        <b-col class="text-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="move('profile-edit')"
          >
            編集する
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-alert
      variant="secondary"
      show
    >
      <div class="alert-body mt-1">
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar
              size="35"
              src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-bule.png?alt=media&token=a579655b-d85a-437a-93ce-034100fcb34e"
              variant="light-primary"
            />
          </div>
          <div class="">
            続けて
            <b>アンケート</b>
            もご入力ください。より希望に沿ったお仕事をご紹介できますよ！
          </div>
        </div>
      </div>
    </b-alert>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BButton, BCard, BAlert, BAvatar,
} from 'bootstrap-vue'
/* eslint-disable */
import menulist from '@/components/conf/アンケート.json'
/* eslint-enable */
import getSingleData from '@/firestore/data/get'
import { showAge } from '@/components/js/filter/birthday'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BAlert,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputForm: {},
      menulist,
      showAge,
    }
  },
  async mounted() {
    const data = {
      collection: 'profile',
    }
    const response = await getSingleData(data)
    if (response.status === 'success') {
      this.inputForm = response.data
    } else {
      window.console.log('error', response.msg)
    }
  },
  methods: {
    move(val) {
      this.$router.push({ name: val })
    },
    showZip(val) {
      if (val !== '') {
        const front = String(val).substring(0, 3)
        const back = String(val).substring(3, String(val).length)
        return `〒${front}-${back}`
      }
      return ''
    },
  },
}
</script>
